<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
        <div class="col col-lg-2"></div>
        <div class="col-md-auto mt-20">
          <rotate-square5></rotate-square5>
        </div>
        <div class="col col-lg-2"></div>
      </div>
      <div class="card card-body printableArea" v-else>
        <h3>
          <b>Jenis Surat Management</b>
          <!-- <span class="pull-right">#5669626</span> -->
        </h3>
        <hr />
        <div>
          <div class="container">
            <div class="row justify-content-md-center">
              <div class="col-md-12">
                <form method="post" @submit.prevent="changeDt">
                  <div class="form-body">
                    <div class="row justify-content-md-center">
                      <label
                        class="text-right mt-2 col-md-3 col-sm-12"
                        style="font-size: 15px; font-weight: 700"
                        >Jenis Surat</label
                      >
                      <div class="col-md-9 col-sm-12">
                        <div class="row">
                          <div class="form-group" style="width: 378px">
                            <input
                              type="text"
                              class="form-control"
                              v-model="cari"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-actions">
                    <div class="row justify-content-md-center">
                      <div class="col-md-7 pl-5">
                        <button
                          type="submit"
                          class="btn btn-success"
                          title="Simpan"
                        >
                          Submit</button
                        >&nbsp;
                        <button
                          type="button"
                          @click="resetInput()"
                          class="btn btn-inverse"
                          title="Reset"
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <Table
              :items="items"
              :filter="filters"
              :header="headers"
              :onAction="get"
              :onDetail="doDetail"
              :onEdit="doEdit"
              @onDelete="doDelete"
              :forbidEdit="forbidUpdate"
              :forbidDetail="forbidRead"
              :forbidDelete="forbidDel"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from "@/components/table/table";
import { RotateSquare5 } from "vue-loading-spinner";

export default {
  components: {
    RotateSquare5,
    Table,
  },
  data() {
    return {
      cari: "",
      headers: {
        // kode_surat: {
        //   label: "Kode Jenis Surat",
        // },
        name: {
          label: "Jenis Surat",
          sortable: true,
        },
        // updated_at: {
        //   label: "Terakhir Diubah",
        //   sortable: true,
        //   type:"date"
        // }
      },
    };
  },
  computed: {
    items() {
      return this.$store.state.jenisSurat.items
        ? this.$store.state.jenisSurat.items.items
        : [];
    },
    filters() {
      return this.$store.state.jenisSurat.items
        ? this.$store.state.jenisSurat.items.filters
        : {};
    },
    state() {
      return this.$store.state.jenisSurat;
    },
    forbidUpdate() {
      if (this.$store.state.profile.permissions.document_type) {
        return !this.$store.state.profile.permissions.document_type.update;
      }
      return false;
    },
    forbidRead() {
      if (this.$store.state.profile.permissions.document_type) {
        return !this.$store.state.profile.permissions.document_type.read;
      }
      return false;
    },
    forbidDel() {
      if (this.$store.state.profile.permissions.document_type) {
        return !this.$store.state.profile.permissions.document_type.delete;
      }
      return false;
    },
  },
  mounted() {
    const state = {
      loaded: false,
      items: [],
    };
    this.$store.commit("jenisSurat/STATE", state);
    this.get(state);
  },
  methods: {
    changeDt() {
      const state = {
        loaded: false,
        items: [],
        cari: this.cari,
      };
      this.$store.commit("jenisSurat/STATE", state);
      this.get(state);
    },
    get(val) {
      this.$store.dispatch("jenisSurat/getJenisSurat", val);
    },
    resetInput() {
      this.cari = "";
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
      };
      this.$store.commit("jenisSurat/STATE", state);
      this.get(state);
    },
    doDetail(val) {
      this.$store.dispatch("jenisSurat/onDetail", val);
    },
    doEdit(val) {
      this.$store.dispatch("jenisSurat/onEdit", val);
    },
    doDelete(val) {
      this.$store.dispatch("jenisSurat/submitDelete", val);
    },
  },
};
</script>
